body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}
.dzu-dropzone {
	overflow: hidden !important;
	border: 1px dashed #d9d9d9 !important;
	padding: 20px;
	background: #FFFFFF;
}
.ql-editor {
	background: #FFFFFF;
}
.ql-toolbar {
	background: #EEEEEE;
}
* {
    font-family: 'Montserrat', sans-serif;
}
